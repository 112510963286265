import {reasonTypes} from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import {HiOutlineDuplicate, HiOutlinePencil, HiOutlineTrash} from 'react-icons/hi';
import {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {initReason} from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import {defaultpageCount, permissionKeys, sortByTypeType} from 'utils/constants';
import {isEqual} from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import {toast} from 'react-toastify';
import {checkPermission, copyToClipboard, formatDateNotTime, messageErrors} from '../../utils/utils';
import {useSelector} from 'react-redux';
import ActionTable from 'components/table/actionTable';
import {orgSelector, userSelector} from 'redux/selectors';
import '../../styles/styles.scss';
import {Table} from 'flowbite-react';
import Sort from 'components/table/sort';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import EmptyState from 'components/commonComponent/emptyState';
import ConfirmModal from 'components/modal/confirmModal';
import { deleteReasonById, getReasonsByFilter } from 'api/reasonApi';
import ReasonModal from 'components/modal/reasonModal/reasonModal';

const reasonEmpty = <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
    <path d="M30 16.6667H23.3333C21.5652 16.6667 19.8695 17.369 18.6193 18.6193C17.369 19.8695 16.6667 21.5652 16.6667 23.3333V63.3333C16.6667 65.1014 17.369 66.7971 18.6193 68.0474C19.8695 69.2976 21.5652 70 23.3333 70H56.6667C58.4348 70 60.1305 69.2976 61.3807 68.0474C62.6309 66.7971 63.3333 65.1014 63.3333 63.3333V23.3333C63.3333 21.5652 62.6309 19.8695 61.3807 18.6193C60.1305 17.369 58.4348 16.6667 56.6667 16.6667H50M30 16.6667C30 18.4348 30.7024 20.1305 31.9526 21.3807C33.2029 22.631 34.8985 23.3333 36.6667 23.3333H43.3333C45.1014 23.3333 46.7971 22.631 48.0474 21.3807C49.2976 20.1305 50 18.4348 50 16.6667M30 16.6667C30 14.8986 30.7024 13.2029 31.9526 11.9526C33.2029 10.7024 34.8985 10 36.6667 10H43.3333C45.1014 10 46.7971 10.7024 48.0474 11.9526C49.2976 13.2029 50 14.8986 50 16.6667M40 40H50M40 53.3333H50M30 40H30.0333M30 53.3333H30.0333" stroke="#D1D5DB" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>;

const ReasonList = (props: any) => {
    const queryClient = useQueryClient();
    const {WRITE_REASON, PLATFORM_ADMIN} = permissionKeys;
    const [t] = useTranslation();
    const {userInfo} = useSelector(userSelector);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [idDelete, setIdDelete] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [reasons, setReasons] = useState([initReason]);
    const [allReasons, setAllReasons] = useState([]);
    const [targetReason, setTargetReason] = useState(initReason);
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const {organizationId} = useSelector(orgSelector);
    const isWriteReason = checkPermission(userInfo, props.type, [WRITE_REASON], organizationId);

    const handleAddReason = () => {
        setOpenModal(true);
        setIsEdit(false);
    };

    const {data, isLoading, refetch, error} = useQuery(
        ['getReasons', currentPage, searchValue, sortBy, sortByType, pageCount],
        () => getReasonsByFilter({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            sortBy,
            sortByType,
            organizationId
        }),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (error) {
            setReasons([initReason]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const getAllReasons = useMutation(['getAllReasons'], {
        mutationFn: getReasonsByFilter,
        onSuccess: ({data}) => setAllReasons(data.entities),
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleSearch = (value: any) => {
        setSearchValue(value);
        setCurrentPage(0);
    };

    const handleCopyToClipboard = (e: any, item: any, type: string) => {
        if (type === 'name') {
            copyToClipboard(item.name, t('categoryPage.copiedName'));
        } else {
            if (type === 'organizationId') {
                copyToClipboard(item.organizationId, t('categoryPage.copiedOrganization'));
            }
        }
        e.stopPropagation();
    };

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC;
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type);
    };

    const handleEdit = async (item: any) => {
        if (isWriteReason) {
            setTargetReason(item);
            setOpenModal(true);
            setIsEdit(true);
        }
    }

    const handleDelete = async (id: string) => {
        setIdDelete(id);
        setOpenModalConfirm(!openModalConfirm);
    };

    const onDelete = async (id: string) => {
        try {
            const response = await deleteReasonById(id);
            if (response.status === 200) {
                await queryClient.invalidateQueries(['getReasons'], {refetchType: 'active'}, {cancelRefetch: true});
                const message: string = t('reasonPage.deleteSuccessfully')
                toast.success(message);
                setOpenModalConfirm(!openModalConfirm);
            }
        } catch (error: any) {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        }
    }

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    useEffect(() => {
        if (data) {
            setReasons(data?.data?.entities || []);
            setTotalEntities(data.data?.totalEntities || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            {error ? <EmptyState buttonName={t('reasonPage.addReason')}
                                 handleAddClick={isWriteReason && handleAddReason}
                                 title={t('reasonPage.titleNoResult')}
                                 subtitle={t('reasonPage.subTitleNoResult')}
                                 renderIcon={reasonEmpty}
                /> :
                <>
                    {!isEqual(reasons[0], initReason) && totalEntities === 0 && searchValue === ''
                        ?
                        <EmptyState buttonName={t('reasonPage.addReason')}
                                    handleAddClick={isWriteReason && handleAddReason}
                                    title={t('reasonPage.titleNoResult')}
                                    subtitle={t('reasonPage.subTitleNoResult')}
                                    renderIcon={reasonEmpty}
                        />
                        :
                        !isEqual(reasons[0], initReason) && totalEntities === 0
                            ?
                            (
                                <div className="p-[30px]">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('reasonPage.addReason')}
                                        handleAddClick={isWriteReason && handleAddReason}
                                        handleSearch={handleSearch}
                                        searchValue={searchValue}
                                    />
                                    <NoResult/>
                                </div>
                            )
                            :
                            (
                                <div className="main-sub">
                                    <ActionTable
                                        placeholderSearch="Search"
                                        buttonName={t('reasonPage.addReason')}
                                        handleAddClick={isWriteReason && handleAddReason}
                                        handleSearch={handleSearch}
                                    />
                                    <Table className="h-full overflow-y-scroll">
                                        <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                            <Table.HeadCell className="cursor-pointer bg-white font-medium text-xs px-3"
                                                            onClick={() => handleHeaderSort('name')}>
                                                <div className="flex items-center ">
                                                    {t('reasonPage.name').toUpperCase()}
                                                    <Sort check={sortBy === 'name'} sortByType={sortByType}/>
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell
                                                className="hidden-mobile w-55 bg-white font-medium text-xs"
                                            >
                                                <div className="flex items-center">
                                                    {t('reasonPage.default').toUpperCase()}
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell
                                                className="hidden-mobile w-44 bg-white font-medium text-xs"
                                            >
                                                <div className="flex items-center">
                                                    {t('reasonPage.created').toUpperCase()}
                                                </div>
                                            </Table.HeadCell>
                                            <Table.HeadCell className="w-6 bg-white">{''}</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body>
                                            {reasons.map((item: reasonTypes, index) => (
                                                <Table.Row key={`row-${index + 1}`}
                                                           className="bg-white text-sm hover:bg-gray-50 border-b border-gray-100 cursor-pointer">
                                                    <Table.Cell className="font-semibold py-2.5 px-3">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div className="text-secondary">{item?.name}</div>
                                                            <div
                                                                className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                <HiOutlineDuplicate size={25}
                                                                                    onClick={(e: any) => handleCopyToClipboard(e, item, 'name')}/>
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-2.5">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div className="text-black">{item?.isSalesDefault ? t('reasonPage.sales') : item?.isReconcileDefault ? t('reasonPage.reconcile') : item?.isShipmentDefault ? t('reasonPage.shipment') : item.isShrinkageDefault ? t('reasonPage.shrinkage') : ''}</div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-2.5">
                                                        <div className="flex flex-row items-center"
                                                             onClick={() => handleEdit(item)}>
                                                            <div className="text-black">{formatDateNotTime(item?.created || '')}</div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="py-2.5">
                                                        {isWriteReason && (
                                                            <GroupButtonWithIcons
                                                                className="items-center justify-center"
                                                                buttons={[
                                                                    {
                                                                        type: 'button',
                                                                        text: '',
                                                                        classType: 'white',
                                                                        icon: <HiOutlinePencil size={'20px'}/>,
                                                                        action: () => handleEdit(item),
                                                                    },
                                                                    {
                                                                        type: 'submit',
                                                                        text: '',
                                                                        classType: 'white',
                                                                        icon: <HiOutlineTrash size={'20px'}/>,
                                                                        action: () => {
                                                                            handleDelete(item?.id)
                                                                        },
                                                                    },
                                                                ]}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                    {isLoading && <SpinnerComponent/>}
                                    <PaginateTable
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        totalEntities={totalEntities}
                                        isLoadingTable={isLoading}
                                    />
                                </div>
                            )
                    }
                </>}
            {openModal && (
                <ReasonModal
                    headerTitle={isEdit ? t('reasonPage.editReason') : t('reasonPage.addReasonHeader')}
                    openModal={openModal}
                    isEdit={isEdit}
                    targetData={targetReason}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
            {openModalConfirm && (
                <ConfirmModal
                    openModal={openModalConfirm}
                    setOpenModal={setOpenModalConfirm}
                    onDelete={() => onDelete(idDelete)}
                />
            )}
        </>
    );
};

export default ReasonList;
