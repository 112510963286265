/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import useUser from 'hooks/useUser';
import {Modal, Textarea} from 'flowbite-react';
import {useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './purchaseOrderModal.scss';
import {copyToClipboard} from 'utils/utils';
import {HiDuplicate, HiOutlineClipboardCopy, HiOutlineDuplicate, HiOutlinePlus} from "react-icons/hi";

type mlvedaErrorModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    data?: any,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const MlvedaErrorModal = (props: mlvedaErrorModalProps) => {
    const {
        openModal,
        data,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);

    const toggleHandler = () => {
        setOpenModal(!openModal);
    };

    const toggleContinue = () => {
        copyToClipboard(data, t('orderPage.errorCopied'))
    };

    return (
            <div ref={rootRef} className="order-modal-container">
                <Modal
                    show={openModal}
                    size="3xl"
                    className="order-modal"
                    root={rootRef.current ?? undefined}
                    onClose={toggleHandler}
                    dismissible={false}
                    theme={themModal}
                >
                    <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                    <Modal.Body theme={{base: 'flex flex-col items-center px-10'}}>
                        <div className="py-5 text-sm font-normal">{t('orderPage.errorN2gSubHeader')}</div>
                        <div className='w-full relative bg-red-50 p-10 rounded-lg border-dashed border border-red-400 text-red-600'>
                            <HiOutlineDuplicate size={"24px"} color="black" className="absolute right-3 top-3 cursor-pointer" 
                            onClick={(e: any) => toggleContinue()}/>
                            <div className="modal-error-report">{data}</div>
                        </div>

                    </Modal.Body>
                    <div className="w-full flex justify-end p-5">
                        <GroupButton
                            className=""
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.dismiss'),
                                    classType: 'blue',
                                    action: () => toggleHandler(),
                                },
                            ]}
                        />
                    </div>
                </Modal>
            </div>
    );
};
export default MlvedaErrorModal;
