import { LogoutPage, DashboardPage, OrganizationList, UsersPage, VersionPage, ContactSupportPage, LoginPage, RoleManagementPage } from 'pages';
import EmailVerification from 'pages/emailVerification';
import MyTokens from 'pages/myTokens/myTokensManagement';
import NotFoundPage from 'pages/notFound';
import { routePaths, permissionKeys, pageType } from 'utils/constants';
import { routeType } from 'utils/proptypes';
import Subscription from '../pages/subscription/subscription';
import MySubscription from 'pages/mySubscriptions/mySubscriptions';
import StripePayment from 'pages/stripePayment/stripePayment';
import OrganizationDetail from 'pages/organization/organizationDetail';
import UserDetail from 'pages/user/userDetail';
import LocationList from 'pages/location/locationList';
import CategoryList from 'pages/categories/categoryList';
import ItemList from 'pages/items/itemList';
import StockList from 'pages/stock/stockList';
import StockLedger from 'pages/stock/stockLedger';
import RegionList from 'pages/region/regionList';
import PurchaseOrderList from 'pages/purchaseOrder/purchaseOrderList';
import PurchaseOrderDetail from 'pages/purchaseOrder/purchaseOrderDetail';
import PurchaseOrderReview from 'pages/purchaseOrder/purchaseOrderReview';
import PurchaseOrderRequestedList from 'pages/purchaseOrder/purchaseOrderRequestedList';
import NotificationList from 'pages/notifications/notificationList';
import ReasonList from 'pages/reasonForDiscrepancy/reasonList';
const { READ_USER, WRITE_USER, READ_ORG, WRITE_ORG, READ_STOCK, WRITE_STOCK, READ_PURCHASE_ORDER, WRITE_PURCHASE_ORDER, REVIEW_PURCHASE_ORDER, READ_REASON, WRITE_REASON } = permissionKeys;
const { READ_ROLE, WRITE_ROLE, READ_AUTHEN, WRITE_AUTHEN } = permissionKeys;
const { SITE, ORGANIZATION, LOCATION } = pageType;
const isUseStripe = process.env.REACT_APP_IS_USE_STRIPE === 'true';
const isUseSubscription = process.env.REACT_APP_IS_USE_SUBSCRIPTION === 'true';


const routes: routeType[] = [
  { path: routePaths.VERSION_PAGE, component: VersionPage },
  { path: routePaths.CONTACT_SUPPORT, component: ContactSupportPage },
  { path: routePaths.LOGIN_PAGE, component: LoginPage, title: 'Huzzard Inventory' },
  { path: routePaths.LOGOUT_PAGE, component: LogoutPage },
  { path: routePaths.DASHBOARD_PAGE, component: DashboardPage },
  { path: routePaths.ORGANIZATION_PAGE, component: OrganizationList, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: SITE },
  { path: routePaths.ORG_LOCATION_PAGE, component: LocationList, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: ORGANIZATION },
  { path: routePaths.ORG_REGION_PAGE, component: RegionList, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: ORGANIZATION },
  { path: routePaths.ORG_CATEGORY_PAGE, component: CategoryList, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: ORGANIZATION },
  { path: routePaths.ORG_ITEM_PAGE, component: ItemList, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: ORGANIZATION },
  { path: routePaths.NOT_FOUND_PAGE, component: NotFoundPage },
  { path: routePaths.ADMIN_USERS_PAGE, component: UsersPage, layout: 'MainLayout', permissions: [READ_USER, WRITE_USER], type: SITE },
  { path: routePaths.ROLES_PAGE, component: RoleManagementPage, layout: 'MainLayout', permissions: [READ_ROLE, WRITE_ROLE], type: SITE },
  { path: routePaths.EMAIL_VERIFICATION, component: EmailVerification },
  { path: routePaths.MY_TOKENS, component: MyTokens, layout: 'MainLayout', permissions: [READ_AUTHEN, WRITE_AUTHEN], type: SITE },
  { path: routePaths.UPDATE_PROFILE },
  { path: routePaths.ORG_USERS_PAGE, component: UsersPage, layout: 'MainLayout', permissions: [READ_USER, WRITE_USER], type: ORGANIZATION },
  { path: routePaths.ORG_OVERVIEW_PAGE, component: OrganizationDetail, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: ORGANIZATION },
  { path: routePaths.ADMIN_USER_DETAIL, component: UserDetail, layout: 'MainLayout', type: SITE },
  { path: routePaths.ORG_USER_DETAIL, component: UserDetail, layout: 'MainLayout', type: ORGANIZATION },
  { path: routePaths.STOCK_PAGE, component: StockList, layout: 'MainLayout', permissions: [READ_STOCK, WRITE_STOCK], type: LOCATION },
  { path: routePaths.STOCK_DETAIL_PAGE, component: StockLedger, layout: 'MainLayout', permissions: [READ_STOCK, WRITE_STOCK], type: LOCATION },
  { path: routePaths.PURCHASE_ORDER_PAGE, component: PurchaseOrderList, layout: 'MainLayout', permissions: [READ_PURCHASE_ORDER, WRITE_PURCHASE_ORDER], type: LOCATION },
  { path: routePaths.PURCHASE_ORDER_REQUESTED_PAGE, component: PurchaseOrderRequestedList, layout: 'MainLayout', permissions: [REVIEW_PURCHASE_ORDER], type: LOCATION },
  { path: routePaths.PURCHASE_ORDER_DETAIL_PAGE, component: PurchaseOrderDetail, layout: 'MainLayout', permissions: [READ_PURCHASE_ORDER, WRITE_PURCHASE_ORDER], type: LOCATION },
  { path: routePaths.PURCHASE_ORDER_REVIEW_PAGE, component: PurchaseOrderReview, layout: 'MainLayout', permissions: [READ_PURCHASE_ORDER, WRITE_PURCHASE_ORDER, REVIEW_PURCHASE_ORDER], type: LOCATION },
  { path: routePaths.NOTIFICATION, component: NotificationList, layout: 'MainLayout', permissions: [READ_ORG, WRITE_ORG], type: ORGANIZATION },
  { path: routePaths.REASON_PAGE, component: ReasonList, layout: 'MainLayout', permissions: [READ_REASON, WRITE_REASON], type: ORGANIZATION },
  { ...(isUseSubscription ? { path: routePaths.SUBSCRIPTION, component: Subscription }: {}) },
  { ...(isUseSubscription ? { path: routePaths.MY_SUBSCRIPTIONS, component: MySubscription , layout: 'MainLayout', permissions: []}: {}) },
  { ...(isUseStripe ? { path: routePaths.STRIPE_PAYMENT, component: StripePayment, layout: 'UserLayout' } : {}) },
];

export default routes;
