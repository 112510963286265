/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {useMutation} from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import {toast} from 'react-toastify';
import {messageErrors} from 'utils/utils';
import useUser from 'hooks/useUser';
import {Label, Modal, Radio} from 'flowbite-react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import InputText from 'components/commonComponent/inputText';
import {orgSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {createReason, updateReasonById} from 'api/reasonApi';

type reasonModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    targetData?: any,
    isEdit: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ReasonModal = (props: reasonModalProps) => {
    const {openModal, targetData, isEdit, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage} = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const [selectedOption, setSelectedOption] = useState(''); // Set default checked

    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        getValues,
    } = hookForm;

    useEffect(() => {
        if (isEdit) {
            setValue('name', targetData.name);
            setValue('organizationId', targetData.organization?.id);
            setValue('isSalesDefault', targetData.isSalesDefault);
            setValue('isReconcileDefault', targetData.isReconcileDefault);
            setValue('isShipmentDefault', targetData.isShipmentDefault);
            setValue('isShrinkageDefault', targetData.isShrinkageDefault);
            setSelectedOption(targetData.isSalesDefault ? 'isSalesDefault' : targetData.isReconcileDefault ? 'isReconcileDefault' : targetData.isShipmentDefault ? 'isShipmentDefault' : targetData.isShrinkageDefault ? 'isShrinkageDefault' : '');
            reset(targetData);
        } else {
            reset();
        }
        // eslint-disable-next-line
    }, []);

    const handleAddAndUpdateReason = async (payload: any) => {
        if (isEdit) {
            payload.organizationId = organizationId;
            payload.organization = {id: organizationId};
            await updateReasonById(targetData.id, payload);
        } else {
            payload.organizationId = organizationId;
            payload.organization = {id: organizationId};
            await createReason(payload);
        }
    };

    const mutation = useMutation(['create-update-reason'], {mutationFn: handleAddAndUpdateReason});

    const onSubmit = (values: any, props: any) => {
        mutation.mutate(values, {
            onSuccess: () => {
                toggleHandler();
                if (currentPage !== 0) {
                    queryClient.invalidateQueries('getReasons', {refetchActive: false}, {cancelRefetch: true});
                    setCurrentPage(0);
                } else queryClient.invalidateQueries('getReasons', {refetchActive: true}, {cancelRefetch: true});
                const message: string = isEdit ? t('reasonPage.editSuccess') : t('reasonPage.saveSuccess');
                toast.success(message);
                fetchCurrentUser();
            },
            onError: error => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
        });
    };

    const handleChangeDefault = (key: string, value: any) => {
        setValue(key, value);
        setSelectedOption(key);
        if (key === 'isReconcileDefault') {
            setValue('isSalesDefault', null as any);
            setValue('isShipmentDefault', null as any);
            setValue('isShrinkageDefault', null as any);
        } else {
            if (key === 'isSalesDefault') {
                setValue('isReconcileDefault', null as any);
                setValue('isShipmentDefault', null as any);
                setValue('isShrinkageDefault', null as any);
            } else {
                if (key === 'isShipmentDefault') {
                    setValue('isReconcileDefault', null as any);
                    setValue('isSalesDefault', null as any);
                    setValue('isShrinkageDefault', null as any);
                } else {
                    setValue('isReconcileDefault', null as any);
                    setValue('isSalesDefault', null as any);
                    setValue('isShipmentDefault', null as any);
                }
            }
        }
    }

    const toggleHandler = () => {
        setOpenModal(!openModal);
        reset();
    };

    return (
        <div ref={rootRef} className="reason-modal-container">
            <Modal
                show={openModal}
                size="xl"
                className="reason-modal"
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex-12 pb-4 pt-3 px-12'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="my-4 relative">
                            <InputText
                                hookForm={hookForm}
                                placeholder={`${t('reasonPage.modalReasonName')}`}
                                name="name"
                                id="name"
                                isHolderLabel={true}
                            />
                        </div>
                        <div className="my-4 relative">
                            <Label>{t('reasonPage.default')}</Label>
                        </div>
                        <div className="my-4 relative flex">
                            <div className="flex items-center">
                                <Radio
                                    id='isSalesDefault'
                                    name='isSalesDefault'
                                    checked={selectedOption === 'isSalesDefault'}
                                    onChange={(e) => handleChangeDefault('isSalesDefault', e.target.checked)}
                                />
                                <Label className="ml-2">{t('reasonPage.sales')}</Label>
                            </div>
                            <div className="flex items-center px-6">
                                <Radio
                                    id='isReconcileDefault'
                                    name='isReconcileDefault'
                                    checked={selectedOption === 'isReconcileDefault'}
                                    onChange={(e) => handleChangeDefault('isReconcileDefault', e.target.checked)}
                                />
                                <Label className="ml-2">{t('reasonPage.reconcile')}</Label>
                            </div>
                            <div className="flex items-center">
                                <Radio
                                    id='isShipmentDefault'
                                    name='isShipmentDefault'
                                    checked={selectedOption === 'isShipmentDefault'}
                                    onChange={(e) => handleChangeDefault('isShipmentDefault', e.target.checked)}
                                />
                                <Label className="ml-2">{t('reasonPage.shipment')}</Label>
                            </div>
                            <div className="flex items-center px-6">
                                <Radio
                                    id='isShrinkageDefault'
                                    name='isShrinkageDefault'
                                    checked={selectedOption === 'isShrinkageDefault'}
                                    onChange={(e) => handleChangeDefault('isShrinkageDefault', e.target.checked)}
                                />
                                <Label className="ml-2">{t('reasonPage.shrinkage')}</Label>
                            </div>
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 mb-4"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.save'),
                                    classType: 'blue',
                                    isLoading: mutation.isLoading,
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default ReasonModal;
