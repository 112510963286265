// eslint-disable-next-line
export default {
  baseUrl: `${process.env.REACT_APP_BASE_API_URL}/v1`,
  user: {
    users: 'Users',
    userProfile: 'users/profile',
    getCurrentUser: 'Users/CurrentUser',
    siteUsers: 'site-users',
    organizationUsers: 'organization-users',
    userControllerChangeEmailAddress: 'Users/ChangeEmailAddress',
    setRoles: 'Users/SetRoles',
    resetPassword: 'Users/reset-password',
    resendEmailVerification: 'Users/Auth0/ResendEmailVerification',
    userEmail: 'users/email',
  },

  role: {
    Roles: 'roles',
    adminRoles: 'roles/adminRoles',
    organizationRoles: 'roles/organizationRoles',
  },

  organization: {
    organizations: 'organizations',
    filter: 'Organizations/Filter',
  },

  userOrganization: {
    userOrganizations: 'UserOrganizations',
  },

  myTokens: {
    myTokens: 'authentication',
  },

  health: 'Health',

  plans: 'plans',
  subscriptions: 'subscriptions',
  stripes: {
    stripes: 'stripes',
    confirm: 'stripes/confirm',
    chargeSubscription: 'stripes/chargeSubscription',
  },

  files: {
    files: 'files',
    generateUploadPresignedUrl: 'files/generateUploadPresignedUrl',
  },

  location: {
    location: 'location',
  },

  region: {
    region: 'region',
  },

  categories: {
    categories: 'category', 
  },

  item: {
    item: 'item', 
  },

  stock: {
    stock: 'stock', 
  },

  reason: {
    reason: 'reason', 
  },

  purchaseOrder: {
    purchaseOrder: 'purchase-order', 
    validateOrderN2G: `${process.env.REACT_APP_N2G_MLVEDA_API_URL}/order/validateOrder/`,
    creationOrderN2G:`${process.env.REACT_APP_N2G_MLVEDA_API_URL}/order/`,
    receivingOrderN2G: `${process.env.REACT_APP_N2G_MLVEDA_API_URL}/order/`
  },

  notification: {
    notification: 'notification', 
  },
};
