// eslint-disable-next-line
import { reasonQueryFilter } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getReasonsByFilter = (queryFilter: reasonQueryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.reason.reason}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getReasons = () => {
  const method = methods.GET;
  const url = `${urls.reason.reason}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getReasonById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.reason.reason}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createReason = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.reason.reason}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateReasonById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.reason.reason}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteReasonById = (id: string) => {
    const method = methods.DELETE;
    const url = `${urls.reason.reason}/${id}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
};

export {
    getReasonsByFilter,
    getReasons,
    getReasonById,
    createReason,
    updateReasonById,
    deleteReasonById
};
